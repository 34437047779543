import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import WrapperView from "../components/WrapperView"

const NotFound = () => (
  <Layout>
    <WrapperView title="">
      <div class="hero">
        <div class="hero-content flex-col lg:flex-row">
          <img src="../404.png" alt="Thank You" className="max-w-xs rounded-lg p-16" />
          <div>
          <h1 class="text-5xl font-bold">You just hit a route that doesn&#39;t exist... the sadness.😕</h1>
          <br/>
          <br/>
          <Link to="/"><button className="btn btn-primary">Try again</button></Link>
          </div>
        </div>
      </div>
    </WrapperView>
  </Layout>
)

export default NotFound
